<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>

export default {
    name: "APP",
    data() {
        return {
            screenWidth: document.body.clientWidth, // 屏幕宽度
            screenHeight: document.body.clientHeight, // 屏幕高度
        }
    },
    created() {
        window.screenWidth = document.body.clientWidth;
        window.screenHeight = document.body.clientHeight;
        this.screenWidth = window.screenWidth;
        this.screenHeight = window.screenHeight;
        this.$store.dispatch("setScreenWidth", this.screenWidth);
        this.$store.dispatch("setScreenHeight", this.screenHeight);
        setTimeout(() => {
            window.screenWidth = document.body.clientWidth;
            window.screenHeight = document.body.clientHeight;
            this.screenWidth = window.screenWidth;
            this.screenHeight = window.screenHeight;
            this.$store.dispatch("setScreenWidth", this.screenWidth);
            this.$store.dispatch("setScreenHeight", this.screenHeight);
        }, 1000)
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
            this.$router.push({
                path: this.$store.state.menuIconIndex
            })
            sessionStorage.removeItem('store')
        }
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
        window.addEventListener('resize', () => {
            window.screenWidth = document.body.clientWidth;
            window.screenHeight = document.body.clientHeight;
            this.screenWidth = window.screenWidth;
            this.screenHeight = window.screenHeight;
            this.$store.dispatch("setScreenWidth", this.screenWidth);
            this.$store.dispatch("setScreenHeight", this.screenHeight);
        })
    },
    mounted() {
        window.screenWidth = document.body.clientWidth;
        window.screenHeight = document.body.clientHeight;
        this.screenWidth = window.screenWidth;
        this.screenHeight = window.screenHeight;
        this.$store.dispatch("setScreenWidth", this.screenWidth);
        this.$store.dispatch("setScreenHeight", this.screenHeight);
        setTimeout(() => {
            window.screenWidth = document.body.clientWidth;
            window.screenHeight = document.body.clientHeight;
            this.screenWidth = window.screenWidth;
            this.screenHeight = window.screenHeight;
            this.$store.dispatch("setScreenWidth", this.screenWidth);
            this.$store.dispatch("setScreenHeight", this.screenHeight);
        }, 500)
    },
    methods: {}
}
</script>
<style>
@import "assets/iconfont.css";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
}

body, html {
    background-color: #eee;
    width: 100%;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.btn-pre-copy {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 13px;
    color: cadetblue;
}

.code-box {
    width: 100%;
    position: relative;
}

.code-pre {
    width: 100%;
    padding: 15px 20px;
}

</style>
