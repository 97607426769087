import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    userInfo: null,
    screenWidth: 0,
    screenHeight: 0,
    isCollapse: false,
    screenWidthRank: 5,
    isConsole: false,
    menuIndex: 0,
    menuIconIndex: '/edit'
  },
  mutations: {
    setMenuIconIndex(state, menuIconIndex) {
      state.menuIconIndex = menuIconIndex
    },
    setMenuIndex(state, menuIndex) {
      state.menuIndex = menuIndex
    },
    setIsConsole(state, isConsole) {
      state.isConsole = isConsole
    },
    setScreenWidthRank(state, screenWidthRank) {
      state.screenWidthRank = screenWidthRank
    },
    setIsCollapse(state, isCollapse) {
      state.isCollapse = isCollapse
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setLogin(state, isLogin) {
      state.isLogin = isLogin
    },
    setScreenWidth(state, screenWidth) {
      state.screenWidth = screenWidth
    },
    setScreenHeight(state, screenHeight) {
      state.screenHeight = screenHeight
    }
  },
  actions: {
    setMenuIconIndex(context, menuIconIndex) {
      context.commit('setMenuIconIndex', menuIconIndex)
    },
    setMenuIndex(context, menuIndex) {
      context.commit('setMenuIndex', menuIndex)
    },
    setIsConsole(context, isConsole) {
      context.commit('setIsConsole', isConsole)
    },
    setScreenWidthRank(context, screenWidthRank) {
      context.commit('setScreenWidthRank', screenWidthRank)
    },
    setIsCollapse(context, isCollapse) {
      context.commit('setIsCollapse', isCollapse)
    },
    setUserInfo(context, userInfo) {
      context.commit('setUserInfo', userInfo)
    },
    setLogin(context, isLogin) {
      context.commit('setLogin', isLogin)
    },
    setScreenWidth(context, screenWidth) {
      context.commit('setScreenWidth', screenWidth)
    },
    setScreenHeight(context, screenHeight) {
      context.commit('setScreenHeight', screenHeight)
    }
  },
  modules: {}
})
