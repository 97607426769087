import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        redirect: "/edit",
        children: [
            {
                path: '/bokeList',
                name: '文章列表',
                component: () => import(/* webpackChunkName: "about" */ '../views/BokeList.vue')
            },
            {
                path: '/edit',
                name: '文章发布',
                component: () => import(/* webpackChunkName: "about" */ '../views/BokeEdit.vue')
            },
            {
                path: '/fileList',
                name: '文件列表',
                component: () => import(/* webpackChunkName: "about" */ '../views/FileList.vue')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch(() => {})
};

export default router
