"use strict";
import { Notification } from 'element-ui';
import axios from "axios";
import confic from "./config";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: confic.baseURL,
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
);

window.axios = _axios;

window.go = function (data) {
  return new Promise(((resolve, reject) => {
    let query = data.query;
    if (query !== undefined && query.length > 0) {
      let queryStr = "&";
      for (const queryKey in query) {
        queryStr += (queryKey + "=" + query[queryKey]);
      }
      data.url = data.url + queryStr;
    }
    window.axios.post(data.url, data.data).then(res => {
      if (res.data.code === 200) {
        resolve(res.data)
      } else {
        reject(res.data)
          Notification.error(res.data.result)
      }
    })
  }));
}
