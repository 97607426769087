import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import router from './router'
import 'font-awesome/css/font-awesome.css'
import "../src/views/EditStyle.css"


ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
    store, router, render: h => h(App)
}).$mount('#app')
